import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PodMonthlyReportFormComponent } from './pod-monthly-report-form/pod-monthly-report-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PodMonthlyReportShowDetailsComponent } from './pod-monthly-report-show-details/pod-monthly-report-show-details.component';
import { BadgeModule } from 'primeng/badge';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  declarations: [
    PodMonthlyReportFormComponent,
    PodMonthlyReportShowDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    ButtonModule,
    DropdownModule,
    FileUploadModule,
    GalleriaModule,
    ImageModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    StyleClassModule,
  ],
})
export class MonthlyReportingProcessModule {}
