import { Component, ElementRef, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router';
import { Roles } from '@core/_enums/roles.enum';
import { AuthenticationService } from '@core/services/store/authentication.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  readonly Roles = Roles;
  itemsMenuTopBar: MenuItem[];
  eventTopBar!: Event;

  userSesionName: string;
  actualRole!: Roles;

  @ViewChild('menubutton') menuButton!: ElementRef<HTMLButtonElement>;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef<HTMLButtonElement>;

  @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    private confirmationService: ConfirmationService,
    private authService: AuthenticationService,
    private router: Router,
  ) {
    this.userSesionName = this.authService.currentUserValue?.user.name ?? '';
    this.actualRole =
      this.authService.currentUserValue?.roles[0] ?? Roles.NotSupported;

    this.itemsMenuTopBar = [
      {
        label: `${this.actualRole.toUpperCase()}  |
                 ${this.userSesionName ? this.userSesionName : ''}`,
        visible: !layoutService.isDesktop(),
        styleClass: 'p-menuitem-label',
      },
      {
        label: 'Account',
        icon: 'pi pi-user',
        command: () => {
          this.router.navigate(['/users/profile']);
        },
      },
      {
        label: 'Support',
        icon: 'pi pi-question-circle',
        visible:
          this.actualRole === Roles.Pastor || this.actualRole === Roles.Liaison,
        command: () => {},
      },
      {
        label: 'Log Out',
        icon: 'pi pi-power-off',
        command: () => {
          console.log(
            '🚀 ~ file: app.topbar.component.ts:47 ~ AppTopBarComponent ~ eventTopBar:',
            this.eventTopBar,
          );
          this.confirmLogOut(this.eventTopBar);
        },
      },
    ];
  }

  confirmLogOut(event: Event) {
    this.confirmationService.confirm({
      key: 'logoutPopup',
      target: event.target ?? undefined,
      message: 'Are you sure that you want to proceed?',
      // icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.authService.logout();
      },
      reject: () => {},
    });
  }
}
