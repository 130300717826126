<div class="layout-menu-container">
  <ul class="layout-menu" role="menu">
    <li
      app-menu
      class="layout-menuitem-category"
      *ngFor="let item of model; let i = index"
      role="none"
    >
      <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">
        {{ item.label }}
      </div>
      <ul role="menu">
        <li
          app-menuitem
          *ngIf="!item.separator"
          [item]="item"
          [index]="i"
          [root]="true"
          [parentKey]="'root_' + i"
          [id]="'root_' + i"
        ></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
      </ul>
    </li>
  </ul>

  <div
    class="mt-3 text-center"
    *ngIf="
      !layoutService.isDesktop() &&
      currentUser.roles.indexOf(Roles.OrderPickers) === -1
    "
  >
    <button
      pButton
      pRipple
      type="button"
      label="NEWS"
      class="p-button-rounded font-bold btn-news-header"
    ></button>
  </div>
</div>
