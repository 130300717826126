import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from '@core/_guards/auth.guard';
import { NavigateGuard } from '@core/_guards/navigate.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        {
          path: '',
          component: AppLayoutComponent,
          canActivateChild: [AuthGuard],
          children: [
            {
              path: 'dashboard',
              loadComponent: () =>
                import('@legacy/dashboard/dashboard.component').then(
                  (c) => c.DashboardComponent,
                ),
              data: {
                role: ['admin', 'pastor', 'liaison', 'dispatchers'],
              },
            },
            {
              path: 'liaison',
              loadChildren: () =>
                import('@legacy/liaison-module/liaison.module').then(
                  (m) => m.LiaisonModule,
                ),
              data: {
                role: 'liaison',
              },
            },
            {
              path: 'pastor',
              loadChildren: () =>
                import('@legacy/pastor-module/pastor.module').then(
                  (m) => m.PastorModule,
                ),
              data: {
                role: 'pastor',
              },
            },
            {
              path: 'leads/index',
              loadComponent: () =>
                import('@legacy/leads/index/index.component').then(
                  (c) => c.IndexComponent,
                ),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'trainings',
              loadChildren: () =>
                import('@legacy/training/training.module').then(
                  (m) => m.TrainingModule,
                ),
            },
            {
              path: 'liaison/index',
              loadComponent: () =>
                import(
                  '@legacy/liaison/indexliaison/indexliaison.component'
                ).then((c) => c.IndexliaisonComponent),
              data: {
                role: ['admin', 'pastor'],
              },
            },
            {
              path: 'users/profile',
              loadComponent: () =>
                import(
                  '@legacy/profiles/users-profile/users-profile.component'
                ).then((c) => c.UsersProfileComponent),
              data: {
                role: ['admin', 'pastor', 'liaison'],
              },
            },
            {
              path: 'pod-accountability',
              loadChildren: () =>
                import(
                  '@legacy/pod-accountability/pod-accountability.module'
                ).then((m) => m.PodAccountabilityModule),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'progress-timeline',
              loadChildren: () =>
                import(
                  '@legacy//progress-timeline/progress-timeline.module'
                ).then((m) => m.ProgressTimelineModule),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'category/list',
              loadComponent: () =>
                import(
                  '@legacy/category/index-category/index-category.component'
                ).then((c) => c.IndexCategoryComponent),
              data: {
                role: ['admin'],
              },
            },
            {
              path: 'category/form',
              loadComponent: () =>
                import('@legacy/orders/form-admin/form-admin.component').then(
                  (c) => c.FormAdminComponent,
                ),
              data: {
                role: ['admin'],
              },
            },
            {
              path: 'order/index',
              loadComponent: () =>
                import(
                  '@legacy/orders/index-order-pastor/index-order-pastor.component'
                ).then((c) => c.IndexOrderPastorComponent),
              data: {
                role: ['admin'],
              },
            },
            {
              path: 'order/new',
              loadComponent: () =>
                import('@legacy/orders/new-order/new-order.component').then(
                  (c) => c.NewOrderComponent,
                ),
              data: {
                role: ['pastor', 'liaison'],
              },
            },
            {
              path: 'dispatchers',
              loadChildren: () =>
                import('@legacy/dispatchers/dispatchers.module').then(
                  (m) => m.DispatchersModule,
                ),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'dispatcher',
              loadChildren: () =>
                import(
                  '@legacy/dispatchers-module/dispatchers-module.module'
                ).then((m) => m.DispatchersModuleModule),
              data: {
                role: 'dispatchers',
              },
            },
            {
              path: 'use-category',
              loadChildren: () =>
                import('@legacy/use-category/use-category.module').then(
                  (m) => m.UseCategoryModule,
                ),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'global-configs',
              loadChildren: () =>
                import('@legacy/global-configs/global-configs.module').then(
                  (m) => m.GlobalConfigsModule,
                ),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'hub-reporting',
              loadChildren: () =>
                import('@legacy/hub-reporting/hub-reporting.module').then(
                  (m) => m.HubReportingModule,
                ),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'support',
              loadChildren: () =>
                import('@legacy/support/support.module').then(
                  (m) => m.SupportModule,
                ),
            },
            {
              path: 'stories-reporting',
              loadChildren: () =>
                import(
                  '@legacy/stories-reporting/stories-reporting.module'
                ).then((m) => m.StoriesReportingModule),
              data: {
                role: 'admin',
              },
            },
            {
              path: 'admin-orders',
              loadChildren: () =>
                import('@legacy/orders/admin-orders.module').then(
                  (m) => m.AdminOrdersModule,
                ),
              data: {
                role: 'admin',
              },
            },
          ],
        },
        {
          path: 'login',
          loadComponent: () =>
            import('@legacy/login/login.component').then(
              (c) => c.LoginComponent,
            ),
          canActivate: [NavigateGuard],
        },
        {
          path: 'error',
          loadComponent: () =>
            import('@legacy/error/error.component').then(
              (c) => c.ErrorComponent,
            ),
        },
        {
          path: 'not-found',
          loadComponent: () =>
            import('@legacy/notfound/notfound.component').then(
              (c) => c.NotfoundComponent,
            ),
        },
        {
          path: 'restricted-access',
          loadComponent: () =>
            import('@legacy/access/access.component').then(
              (c) => c.AccessComponent,
            ),
        },
        {
          path: 'interest-form',
          loadComponent: () =>
            import(
              '@legacy/formsExterns/interest-form/interest-form.component'
            ).then((c) => c.InterestFormComponent),
        },
        {
          path: 'bck-interest-form',
          loadComponent: () =>
            import(
              '@legacy/formsExterns/interest-form/interest-form.component'
            ).then((c) => c.InterestFormComponent),
        },
        {
          path: 'cs/interest-form',
          loadComponent: () =>
            import(
              '@legacy/formsExterns/interest-form/interest-form.component'
            ).then((c) => c.InterestFormComponent),
        },
        {
          path: 'pod-application/:id',
          loadComponent: () =>
            import('@legacy/formsExterns/pod-form/pod-form.component').then(
              (c) => c.PodFormComponent,
            ),
        },
        {
          path: 'message-success',
          loadComponent: () =>
            import(
              '@legacy/formsExterns/formsuccess/formsuccess.component'
            ).then((c) => c.FormsuccessComponent),
        },
        {
          path: 'password/reset',
          loadComponent: () =>
            import('@legacy/reset-password/reset-password.component').then(
              (c) => c.ResetPasswordComponent,
            ),
        },
        {
          path: 'training-accept/:idTraining/:idUser',
          loadChildren: () =>
            import(
              '@legacy/pastor-training-accept/pastor-training-accept.module'
            ).then((m) => m.PastorTrainingAcceptModule),
        },
        { path: '**', redirectTo: 'not-found' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
