import { MenuItem, PrimeIcons } from 'primeng/api';

export const DispatcherRoutes: MenuItem[] = [
  {
    items: [
      {
        label: 'DASHBOARD',
        id: 'root',
        state: {
          levelMenu: 'root'
        },
        icon: PrimeIcons.HOME,
        routerLink: ['/dispatcher/dashboard'],
      },
      {
        label: 'Pending Orders',
        id: 'root',
        state: {
          levelMenu: 'root'
        },
        icon: PrimeIcons.LIST,
        routerLink: ['/dispatcher/orders-assigned'],
      },
    ],
  },
];
