import { MenuItem, PrimeIcons } from 'primeng/api';

export const AdminRoutes: MenuItem[] = [
  {
    items: [
      {
        label: 'DASHBOARD',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.HOME,
        routerLink: ['/dashboard'],
      },
      {
        label: 'ON BOARDING',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.DESKTOP,
        items: [
          {
            label: 'Leads',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/leads/index'],
          },
          {
            label: 'Trainings',
            icon: PrimeIcons.CIRCLE_FILL,
            items: [
              {
                label: 'Senior Pastor Trainings',
                icon: 'pi pi-fw pi-calendar-plus',
                routerLink: ['/trainings/admin/pastor'],
              },
              {
                label: 'Liaison Trainings',
                icon: 'pi pi-fw pi-calendar-plus',
                routerLink: ['/trainings/admin/liaison'],
              },
            ],
          },
          {
            label: 'Progress Overview',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/progress-timeline'],
          },
        ],
      },
      {
        label: 'POD ACCOUNTABILITY',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.USERS,
        badge: 'New',
        badgeStyleClass: 'danger',
        routerLink: ['/pod-accountability'],
        /*  items: [
                    {
                        label: 'Churches list',
                        icon: PrimeIcons.CIRCLE_FILL,                       
                        routerLink: ['/pod-accountability'],
                    },
                ], */
      },
      {
        label: 'ORDER DATES',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.CALENDAR,
        badge: 'DEMO',
        badgeStyleClass: 'primary',
        routerLink: ['/admin-orders/calendar-order-dates'],
      },
      {
        label: 'ORDERS',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.CHECK_SQUARE,
        items: [
          {
            label: 'Order Lists',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/order/index'],
          },
        ],
      },
      {
        label: 'HUB REPORTING',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.SITEMAP,
        badge: 'New',
        badgeStyleClass: 'danger',
        routerLink: ['/hub-reporting'],
      },
      {
        label: 'STORIES REPORTING',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.BOOK,
        badge: 'New',
        badgeStyleClass: 'danger',
        routerLink: ['/stories-reporting'],
      },
      {
        label: 'SUPPORT',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.COMMENTS,
        badge: 'New',
        badgeStyleClass: 'danger',
        items: [
          {
            label: 'New Inquather (coming soon)',
            icon: PrimeIcons.CIRCLE_FILL,
          },
          {
            label: 'Completed Videos',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
            routerLink: ['/support/completed-videos'],
          },
        ],
      },
      {
        label: 'GLOBAL CONFIGS',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.WRENCH,
        badge: 'New',
        badgeStyleClass: 'danger',
        items: [
          {
            label: 'Forms',
            icon: PrimeIcons.CIRCLE_FILL,
            items: [
              {
                label: 'Resources Categories',
                icon: PrimeIcons.CIRCLE_FILL,
                routerLink: ['/category/list'],
              },
              {
                label: 'Order', //order for show New Order form
                icon: PrimeIcons.CIRCLE_FILL,
                routerLink: ['/category/form'],
              },
              {
                label: 'G&K Categories',
                icon: PrimeIcons.CIRCLE_FILL,
                routerLink: ['/use-category'],
              },
            ],
          },
          {
            label: 'Order Pickers',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/dispatchers'],
          },
          {
            label: 'Pallets Cost',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/global-configs/pallets-cost'],
          },
          {
            label: 'Presentation Training',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
            routerLink: ['/global-configs/presentation-training-url'],
          },
          {
            label: 'Pick Up Times',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/global-configs/pickup-times-new-order'],
          },
          {
            label: 'Community Engagement',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
            routerLink: ['/global-configs/community-engagement'],
          },
          {
            label: 'News',
            icon: PrimeIcons.CIRCLE_FILL,
          },
        ],
      },
    ],
  },

  /*  {
        label: 'Liaison',
        items: [
            {
                label: 'List',
                icon: 'pi pi-fw pi-users',
                routerLink: ['/liaison/index'],
            },
        ],
    }, */
];
