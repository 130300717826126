import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AdminOrdersModule } from '@legacy/orders/admin-orders.module';
import { InventoryProcessModule } from '@legacy/inventory-process/inventory-process.module';
import { MonthlyReportingProcessModule } from '@legacy/monthly-reporting-process/monthly-reporting-process.module';
import { DialogService } from 'primeng/dynamicdialog';
import { httpInterceptorProviders } from '@core/_interceptors';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    AdminOrdersModule,
    InventoryProcessModule,
    MonthlyReportingProcessModule,
    ConfirmDialogModule,
    ToastModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    httpInterceptorProviders,
    ConfirmationService,
    DialogService,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
