import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@core/services/store/authentication.service';
import { StorageService } from '@core/services/utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class NavigateGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkUserOnSession();
  }

  private checkUserOnSession(): boolean {
    if (
      this.authenticationService.currentUserValue ||
      this.storageService.getLocal('user') ||
      this.storageService.getSession('user')
    ) {
      this.router.navigate(['/dashboard'], { replaceUrl: true });
      return false;
    }

    // Stay on Login Page
    return true;
  }
}
