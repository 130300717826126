<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/images/logos/{{
        layoutService.config().colorScheme === 'dark'
          ? 'city_blue'
          : 'city_blue'
      }}.svg"
      alt="logo"
    />
  </a>

  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <!-- BUTTON NEWS admin,pastor,liaison -->
  <div
    class="mr-auto ml-auto"
    *ngIf="layoutService.isDesktop() && actualRole !== Roles.OrderPickers"
  >
    <button
      pButton
      pRipple
      type="button"
      label="NEWS"
      class="p-button-rounded font-bold btn-news-header"
    ></button>
  </div>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="menuTopBar.toggle($event); eventTopBar = $event"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible,
    }"
  >
    <!-- <a href="#" class="p-link layout-topbar-button">
              <i class="pi pi-calendar"></i>
              <span>Calendar</span>
          </a>
          <a href="#" class="p-link layout-topbar-button">
              <i class="pi pi-cog"></i>
              <span>Settings</span>
          </a> -->
    <a class="m-auto font-bold text-cyan-800">
      <span
        >{{ actualRole.toUpperCase() }} |
        {{ userSesionName ? (userSesionName | titlecase) : "" }}</span
      >
    </a>

    <button
      class="p-link layout-topbar-button"
      pTooltip="Options"
      tooltipPosition="left"
      (click)="menuTopBar.toggle($event); eventTopBar = $event"
    >
      <i class="pi pi-cog"></i>
      <span>Settings</span>
    </button>
  </div>
</div>
<p-menu #menuTopBar [popup]="true" [model]="itemsMenuTopBar">
  <!-- *ngIf="layoutService.topMenuActive" -->
  <a class="m-auto font-bold text-cyan-800">
    <span
      >{{ actualRole.toUpperCase() }} |
      {{ userSesionName ? (userSesionName | titlecase) : "" }}</span
    >
  </a>
</p-menu>
<p-confirmPopup key="logoutPopup"></p-confirmPopup>
