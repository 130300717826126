<p-toast key="global-toast" [baseZIndex]="10001"></p-toast>
<p-toast
  key="global-toast-tr"
  [baseZIndex]="10001"
  position="top-right"
></p-toast>
<p-toast
  key="global-toast-bc"
  [baseZIndex]="10001"
  position="bottom-center"
></p-toast>
<p-confirmDialog
  key="global-confirm-dialog"
  [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '60%' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<p-confirmDialog
  key="global-dialog"
  [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '60%' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
  styleClass="global-dialog-message"
></p-confirmDialog>
<router-outlet></router-outlet>
