import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Roles } from '@core/_enums/roles.enum';
import { IRoutes } from '@core/models/routes.model';
import { IUserResponse } from '@core/models/user';
import { GlobalConfigsService } from '@core/services/data-access/global-configs.service';
import { LiaisonService } from '@core/services/data-access/liaison.service';
import { AuthenticationService } from '@core/services/store/authentication.service';
import { StorageService } from '@core/services/utils/storage.service';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { _Constants } from '@core/_constants';
import { AdminRoutes } from '@core/_routes/routes-admin';
import { DispatcherRoutes } from '@core/_routes/routes-dispatcher';
import { LiaisonRoutes } from '@core/_routes/routes-liaison';
import { PastorRoutes } from '@core/_routes/routes-pastor';
import { IGlobalConfig } from '@core/models/global-config.model';
import { ResponseObject } from '@core/models/liaison';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model!: MenuItem[] | IRoutes[];

  currentUser!: IUserResponse;

  Roles = Roles;

  constructor(
    public layoutService: LayoutService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private liaisonService: LiaisonService,
    private globalConfigsService: GlobalConfigsService,
  ) {}

  ngOnInit() {
    this.model = this.getRoutesByRole();

    setTimeout(() => {
      this.getAll();
    }, 600);
  }

  private getRoutesByRole() {
    const currentUser = (this.authenticationService.currentUserValue ||
      this.storageService.getLocal('user') ||
      this.storageService.getSession('user')) as IUserResponse;

    console.log('Getting Routes By Role');
    console.log(currentUser);

    this.currentUser = currentUser;

    if (currentUser && currentUser.roles.indexOf(Roles.Admin) !== -1) {
      console.log('admin');
      return AdminRoutes;
    } else if (currentUser && currentUser.roles.indexOf(Roles.Pastor) !== -1) {
      console.log('pastor');
      this.checkExternalLinksFromConfigurations(PastorRoutes);
      return PastorRoutes;
    } else if (currentUser && currentUser.roles.indexOf(Roles.Liaison) !== -1) {
      console.log('Liaison');

      this.checkExternalLinksFromConfigurations(LiaisonRoutes);

      return LiaisonRoutes;
    } else if (
      currentUser &&
      currentUser.roles.indexOf(Roles.OrderPickers) !== -1
    ) {
      console.log('Dispatcher');
      return DispatcherRoutes;
    }

    return [];
  }
  private changeStyle() {
    let action: any;
    action = document.getElementById('optionsActive');
    action
      .getElementsByClassName('p-element')[0]
      .setAttribute('style', 'color:red');
  }

  async getAll() {
    try {
      const currentUser =
        this.authenticationService.currentUserValue ||
        this.storageService.getLocal('user') ||
        this.storageService.getSession('user');
      if (currentUser && currentUser.roles.indexOf(Roles.Pastor) !== -1) {
        const response = (await this.liaisonService.getLiaison(
          1,
          currentUser.user.organization.id,
        )) as ResponseObject;
        if (response.data.data.length == 0) {
          this.changeStyle();
        }
      }
    } catch (err) {}
  }

  private async checkExternalLinksFromConfigurations(
    LiasonOrPastorRoutes: MenuItem[],
  ) {
    LiasonOrPastorRoutes.map((menuItem) => {
      menuItem.items?.map((item) => {
        if (item.id?.includes(_Constants.KeyCommunityEngagementMenuItem)) {
          // console.log('🚀 KeyCommunityEngagement Detected', item);
          this.fetchAsyncCommunityEngagementLinks(item);
        } else if (item.id?.includes(_Constants.KeyTrainingMenuItem)) {
          // console.log('🚀 KeyTrainingMenuItem Detected', item);
          this.fetchAsyncTrainingPresentationLink(item);
        }
      });
    });
  }

  private async fetchAsyncCommunityEngagementLinks(item: MenuItem) {
    this.globalConfigsService.getAllCommunityEngagement().then(
      (response) => {
        console.log(
          '🚀 ~ fetchAsyncCommunityEngagementLink ~ response:',
          response,
        );
        //CLean State items Community Engagement
        item.items = [];
        //  Push new State items
        response.data.map((value) =>
          item.items?.push({
            label: value.name,
            icon: PrimeIcons.CIRCLE_FILL,
            url: value.url,
            target: '_blank',
          }),
        );
      },
      (error) => {
        console.log('🚀 ~ fetchAsyncCommunityEngagementLink ~ error:', error);
      },
    );
  }

  private async fetchAsyncTrainingPresentationLink(item: MenuItem) {
    this.globalConfigsService
      .getConfigByKey(_Constants.KeyGlobalConfigTrainingPresentation)
      .then(
        (response) => {
          console.log(
            '🚀 ~ fetchAsyncTrainingPresentationLink ~ response:',
            response,
          );
          // If Exist Trainining Presentation Link inside global Config
          if ((response.data as IGlobalConfig[]).length > 0) {
            item.items?.map((subItem) => {
              // Validating KeyTrainingPresentationSubMenuItem
              if (
                subItem.id === _Constants.KeyTrainingPresentationSubMenuItem
              ) {
                subItem.target = '_blank';
                subItem.url = (response.data as IGlobalConfig[]).at(0)?.value;
              }
            });
          }
        },
        (error) => {
          console.log(
            '🚀 ~ fetchAsyncTrainingPresentationLink ~ error:',
            error,
          );
        },
      );
  }
}
