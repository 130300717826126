import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';

import { AuthenticationService } from '@core/services/store/authentication.service';
import { StorageService } from '@core/services/utils/storage.service';
import { Roles } from '@core/_enums/roles.enum';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser =
      this.authenticationService.currentUserValue ||
      this.storageService.getLocal('user') ||
      this.storageService.getSession('user');

    if (currentUser) {
      return true;
    }

    this.router.navigate(['/login'], {
      replaceUrl: true,
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser =
      this.authenticationService.currentUserValue ||
      this.storageService.getLocal('user') ||
      this.storageService.getSession('user');

    if (currentUser) {
      console.log('Roles#CurrentUser => ', currentUser.roles);
      console.log('Roles#RouteData => ', route.data);

      // console.log('Route => ' , route);

      return this.checkUserRoles(route, currentUser);
    }

    this.router.navigate(['/login'], {
      replaceUrl: true,
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  private checkUserRoles(route: ActivatedRouteSnapshot, currentUser: any) {
    // console.log(
    //     'Access#Roles',
    //     route.data.role instanceof Array ? 'array' : 'single-string'
    // );

    /*
        //Roles coming from Array
        */
    if (route.data['role'] && route.data['role'] instanceof Array) {
      // console.log('#Roles By Array');

      //checking array roles inside array routes roles
      if (
        currentUser.roles.some((v: string) => route.data['role'].includes(v))
      ) {
        //found some role access
        // console.log('#found-some Access Role');
        console.log(route.url[0].path);
        console.log(currentUser.roles);

        //check if accesing on dashboard route main and role user is "pastor"
        if (route.url[0].path === 'dashboard') {
          if (currentUser.roles.indexOf(Roles.Pastor) !== -1) {
            this.router.navigate(['/pastor/dashboard'], {
              replaceUrl: true,
            });
            return false;
          }
          if (currentUser.roles.indexOf(Roles.Liaison) !== -1) {
            this.router.navigate(['/liaison/dashboard'], {
              replaceUrl: true,
            });
            return false;
          }

          if (currentUser.roles.indexOf(Roles.OrderPickers) !== -1) {
            this.router.navigate(['/dispatcher/dashboard'], {
              replaceUrl: true,
            });
            return false;
          }
        }
      } else {
        // console.log('notFound Access Role');

        this.router.navigate(['/restricted-access']);
        return false;
      }
    } else if (
      route.data['role'] &&
      currentUser.roles.indexOf(route.data['role']) === -1
    ) {
      /*
        //Roles coming from STRING
        */
      this.router.navigate(['/restricted-access']);
      return false;
    }

    return true;
  }
}
