import { MenuItem, PrimeIcons } from 'primeng/api';
import { _Constants } from '../_constants';

export const LiaisonRoutes: MenuItem[] = [
  {
    items: [
      {
        label: 'DASHBOARD',
        id: 'root',
        state: {
          levelMenu: 'root'
        },
        icon: PrimeIcons.HOME,
        routerLink: ['/liaison/dashboard'],
      },
      {
        id: 'trainings',
        state: {
          levelMenu: 'root'
        },
        label: 'TRAININGS',
        icon: PrimeIcons.BOOKMARK,
        badge: 'New',
        badgeStyleClass: 'danger',
        items: [
          {
            label: 'Upcoming',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/trainings/list/liaison'],
          },
          {
            id: 'training-presentation-url',
            label: 'Completed (presentation)',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
          },
          {
            label: 'Refresh Videos',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
            routerLink: ['/support/training-refresh-videos'],
          },
        ],
      },
      {
        label: 'MONTHLY RESPONSABILITIES',
        id: 'root',
        state: {
          levelMenu: 'root'
        },
        icon: PrimeIcons.CHECK_SQUARE,
        items: [
          {
            label: 'Orders, Inventory & Reports',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/liaison/order/index'],
          },
          {
            label: 'Sustainability Payments',
            icon: PrimeIcons.CIRCLE_FILL,
          },
        ],
      },
      {
        label: 'COMMUNITY ENGAGEMENT',
        id: _Constants.KeyCommunityEngagementMenuItem,
        state: {
          levelMenu: 'root',
        },
        icon: 'assets/icons/heart-handshake.svg',
        iconClass: 'custom-icon',
        badge: 'New',
        badgeStyleClass: 'danger',
        items: [],
      },
    ],
  },
];
