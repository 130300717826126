import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryFormComponent } from './inventory-form/inventory-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryShowDetailsComponent } from './inventory-show-details/inventory-show-details.component';
import { InventoryDeferredFormComponent } from './inventory-deferred-form/inventory-deferred-form.component';
import { InventoryDeferredShowDetailsComponent } from './inventory-deferred-show-details/inventory-deferred-show-details.component';
import { ModalPercentageUseInventoryComponent } from '@core/shared/ui/modal-percentage-use-inventory/modal-percentage-use-inventory.component';
import { NgxPrintModule } from 'ngx-print';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    InventoryFormComponent,
    InventoryShowDetailsComponent,
    InventoryDeferredFormComponent,
    InventoryDeferredShowDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalPercentageUseInventoryComponent,
    NgxPrintModule,
    BadgeModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ProgressSpinnerModule
  ],
})
export class InventoryProcessModule {}
