<section
  class="layout-footer flex-column sm:flex-column md:flex-row lg:flex-row xl:flex-row"
>
  <img
    src="assets/images/logos/city_blue.svg"
    alt="Logo CityServe Black"
    class="mr-2 logo-footer"
  />
  by
  <span class="font-bold ml-2">HUBDV LLC - HUB Developers Team</span>
</section>

<div class="appVersion">
  {{ version }}
</div>
