import { MenuItem, PrimeIcons } from 'primeng/api';
import { _Constants } from '../_constants';

export const PastorRoutes: MenuItem[] = [
  {
    items: [
      {
        label: 'DASHBOARD',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.HOME,
        routerLink: ['/pastor/dashboard'],
      },
      {
        label: 'POD COVENANT',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.BOOK,
        routerLink: ['/pastor/pod-covenant'],
      },
      {
        id: 'trainings',
        state: {
          levelMenu: 'root',
        },
        label: 'TRAININGS',
        icon: PrimeIcons.BOOKMARK,
        badge: 'New',
        badgeStyleClass: 'danger',
        items: [
          {
            label: 'Upcoming',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/trainings/list/pastor'],
          },
          {
            id: 'training-presentation-url',
            label: 'Completed (presentation)',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
          },
          {
            label: 'Refresh Videos',
            icon: PrimeIcons.CIRCLE_FILL,
            badge: 'New',
            badgeStyleClass: 'danger',
            routerLink: ['/support/training-refresh-videos'],
          },
        ],
      },
      {
        label: 'LIAISON',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        styleClass: 'optionsActive',
        icon: PrimeIcons.USERS,
        routerLink: ['/pastor/manage-liaison'],
      },
      {
        label: 'MONTHLY RESPONSABILITIES',
        id: 'root',
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.CHECK_SQUARE,
        items: [
          {
            label: 'Orders, Inventory & Reports',
            icon: PrimeIcons.CIRCLE_FILL,
            routerLink: ['/pastor/order/index'],
          },
          {
            label: 'Sustainability Payments',
            icon: PrimeIcons.CIRCLE_FILL,
          },
        ],
      },
      {
        label: 'COMMUNITY ENGAGEMENT',
        id: _Constants.KeyCommunityEngagementMenuItem,
        state: {
          levelMenu: 'root',
        },
        icon: PrimeIcons.INFO_CIRCLE,
        badge: 'New',
        badgeStyleClass: 'danger',
        items: [],
      },
    ],
  },
];
